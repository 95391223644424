<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 text-light font-weight-bold"
          style="font-size: 3.5vw"
        >
          Let’s start playing
          <br />the game to better
          <br />your future
        </div>
      </b-col>
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <!-- Brand logo-->
        <b-link style="position: absolute; top:0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt class="logo" />
        </b-link>
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 text-light">
            <p class="mb-3" style="font-size: 1.2em">Welcome to The Hustle Lab!</p>
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- Make your app management easy and fun! -->
            <b-col class="text-muted p-0">
              <p class="m-0 mb-2 p-0">We are happy to see that you have joined The Hustle Lab!</p>
              <p
                class="m-0 p-0"
              >You would have received an invitation code in an email, please enter the code below to sign up.</p>
            </b-col>
          </b-card-text>

          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <b-form-group
                label="Verification Code"
                class="mt-3 light-label"
                label-for="register-verification"
              >
                <validation-provider
                  #default="{ errors }"
                  name="verification"
                  vid="verification"
                  class="inner-addon left-addon"
                  rules="required"
                >
                  <feather-icon icon="LockIcon" size="18" />
                  <b-form-input
                    id="register-email"
                    v-model="verification_code"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    class="custom-form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || load"
                class="rounded-pill shadow-none mt-3"
                style="background-color: #E84185 !important; border-color: #E84185 !important"
              >
                <center v-if="load">
                  <b-spinner variant="light" label="Spinning"></b-spinner>
                </center>
                <span v-else>
                  Submit
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span style="color: #E84185">&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BSpinner,
  BCardText
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      username: "",
      userEmail: "",
      password: "",
      verification_code: null,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      required,
      email
    };
  },
  computed: {
    ...mapGetters({
      load: "users/load",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const payload = {
            query: {
              verification_code: this.verification_code,
              email: localStorage.getItem("userEmail")
            }
          };
          this.$store
            .dispatch("users/verifyCode", payload)
            .then(res => {
              this.$router.push({ name: "avatars" });
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  icon: "error",
                  title: `<h4>${error.response.data.message}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: "#E84185",
                  allowOutsideClick: true
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif !important;
}
.logo {
  width: 200px;
}
@media (max-width: 992px) {
  .logo {
    width: 100px;
  }
  .auth-inner {
    display: flex !important;
    justify-content: center !important;
  }
}

.auth-wrapper {
  background: url("../../../assets/images/banner/dark_bakground.png") no-repeat;
  background-size: cover;
}
.auth-bg {
  background-color: #5a5a5a32 !important;
  color: #fff !important;
}

.custom-form-control {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #888 !important;
  height: 50px;
}
.inner-addon {
  position: relative;
}
.inner-addon .feather-mail,
.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}

.left-addon input {
  padding-left: 40px;
  color: #fff !important;
}
.password-addon {
  padding-left: 40px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  color: #fff !important;
}
</style>
